// EventDetails.js
import React, { useState } from 'react';
import './EventDetails.css';
import Modal from '../components/common/Modal';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

// Импорт изображений
const image1 = "/images/photo_2.jpg";
const image2 = "/images/slide_hero_5.jpg";
const image3 = "/images/photo_3.jpg";


const EventDetails = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleBookDates = (data) => {
    console.log("User data submitted:", data);
  };

  return (
    <div className="event-details-page">
       <Navbar /> {/* Добавляем Navbar */}
      {/* Герой-секция */}
      <div className="hero-section-event" style={{ backgroundImage: `url(${image1})` }}>
        <div className="hero-overlay">
          <h1>📚 Книжные Быстрые Свидания</h1>
          <p>Откройте новые литературные связи!</p>
           {/* Кнопка-стрелка для скролла вниз */}
           <a href="#event-info" className="scroll-down">
            <svg width="40" height="40" viewBox="0 0 24 24">
              <path fill="#ffffff" d="M12,16.5L6,10.5L7.41,9.09L12,13.67L16.59,9.09L18,10.5L12,16.5Z">
                <animateTransform 
                  attributeName="transform" 
                  type="translate" 
                  values="0 0; 0 5; 0 0" 
                  dur="1s" 
                  repeatCount="indefinite" />
              </path>
            </svg>
          </a>
        </div>
      </div>

      {/* Информация о событии */}
      <div className="event-info">
        <div className="event-description">
          <p>
            Вы любите книги и хотите встретить единомышленников? 
            <br></br>Приглашаем вас на уникальное
            онлайн-мероприятие «Книжные Быстрые Свидания»! 
            <br></br>Проведите вечер, общаясь с 
            людьми о любимых книгах в увлекательном формате.
          </p>
        </div>

        {/* Детали события */}
        <div className="event-details">
          <div className="detail-item">
            <h2>🗓 Дата и время:</h2>
            <p>19 декабря 2024 года, 19:00 - 20:30 (МСК)</p>
          </div>
          <div className="detail-item">
            <h2>📍 Формат:</h2>
            <p>Онлайн через платформу Zoom (ссылка будет отправлена после регистрации)</p>
          </div>
          <div className="detail-item">
            <h2>Что вас ждет:</h2>
            <ul>
              <li><strong>Интерактивные сессии:</strong> обсуждения в формате быстрых свиданий по 15 минут с каждым участником.</li>
              <li><strong>Темы для беседы:</strong> подготовленные вопросы и темы для интересных и насыщенных разговоров.</li>
              <li><strong>Возможность познакомиться:</strong> найдите новых друзей и обсудите ваши любимые книги с единомышленниками.</li>
            </ul>
          </div>
          <div className="detail-item">
            <h2>Как принять участие:</h2>
            <ol>
              <li>Зарегистрируйтесь до 15 декабря 2024 года.</li>
              <li>Присоединяйтесь к нам в назначенное время и наслаждайтесь вечером литературных бесед!</li>
            </ol>
          </div>
          <div className="detail-item">
            <h2>Стоимость участия:</h2>
            <p>Бесплатно!</p>
          </div>
          <div className="detail-item">
            <h2>Почему стоит прийти:</h2>
            <ul>
              <li><strong>Разнообразие мнений:</strong> узнайте, какие книги вдохновляют других участников.</li>
              <li><strong>Социальное взаимодействие:</strong> расширьте свой кругозор и найдите новых друзей.</li>
              <li><strong>Обмен рекомендациями:</strong> получите новые идеи для чтения и поделитесь своими любимыми произведениями.</li>
            </ul>
          </div>
          <div className="detail-item">
            <h2>📌 Регистрация обязательна. Количество мест ограничено.</h2>
          </div>
          <div>
            <button className="event__button" onClick={() => setModalOpen(true)}>Регистрация</button>
          </div>
        </div>

        {/* Галерея изображений */}
        <div className="image-gallery">
          <img src={image2} alt="Событие 2" />
          <img src={image3} alt="Событие 3" />
        </div>
      </div>
      {/* Условный рендеринг модального окна */}
      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          onSubmit={handleBookDates}
          title="Книжные быстрые свидания"
          description="Заполните информацию о себе."
          fields={[
            { name: "name", placeholder: "Ваше Имя", type: "text" },
            { name: "telegram", placeholder: "Телеграм", type: "text" }
          ]}
          buttonLabel="Зарегистрироваться"
          messagePrefix="[КНИЖНЫЕ СВИДАНИЯ]"
        />
      )}
      <Footer />
    </div>
  );
};

export default EventDetails;
