import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Find the root element in your HTML file
const rootElement = document.getElementById('root');

if (rootElement) {
  // Use createRoot instead of ReactDOM.render
  const root = createRoot(rootElement);
  root.render(<App />);
} else {
  console.error("Root element not found");
}
