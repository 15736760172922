// EventHighlight.js
import React, { useState, useEffect } from 'react';
import './EventHighlight.css';
import { useNavigate } from 'react-router-dom';


const EventHighlight = () => {
  const [event, setEvent] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); // Добавлено здесь

  useEffect(() => {
    fetch(`${apiUrl}/v1/events/6`)
      .then(response => response.json())
      .then(data => setEvent(data))
      .catch(error => console.error('Ошибка при получении данных о событии:', error));
  }, [apiUrl]);

  if (!event) {
    return null;
  }

  // Форматирование даты и времени
  const eventDate = new Date(event.date);
  const formattedDate = eventDate.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const formattedTime = eventDate.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const handleLearnMore = () => {
    navigate('/event-details');
  };

  return (
    <div className="event-container">
      <h2 className="event-title">{event.title}</h2>
      <h3 className="event-subtitle">{event.description}</h3>
      <div className="event-details">
        <div className="event-detail-row">
          <span className="event-detail-label">📍 Город:</span>
          <span className="event-detail-value">{event.city}</span>
        </div>
        <div className="event-detail-row">
          <span className="event-detail-label">📅 Дата:</span>
          <span className="event-detail-value">{formattedDate}</span>
        </div>
        <div className="event-detail-row">
          <span className="event-detail-label">⏰ Время:</span>
          <span className="event-detail-value">{formattedTime}</span>
        </div>
        <div className="event-detail-row">
          <span className="event-detail-label">💸 Цена:</span>
          <span className="event-detail-value">
            {event.price === 0 ? 'Бесплатно' : `${event.price} руб.`}
          </span>
        </div>
      </div>
      <button className="event-button" onClick={handleLearnMore}>
        Узнать больше
      </button>
    </div>
  );
};

export default EventHighlight;
